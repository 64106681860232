<template>
  <div class="warining">
    <van-collapse v-model="activeName" accordion>
      <van-collapse-item title="筛选条件" class="filter">
           <van-cell title="按设备编号查" :value="filter.area.value" is-link @click="filter.area.showPicker = true"/>
           <van-cell title="按告警类型查" :value="filter.way.value" is-link @click="filter.way.show = true"/>
           <van-cell title="按时间段查询" :value="filter.time.value" is-link @click="filter.time.show = true"/>
           <van-row class="text-center">
              <van-col span="12"><van-button type="info" size="small" @click="resetData">重置</van-button></van-col>
              <van-col span="12"> <van-button type="info" size="small" @click="filterData">筛选</van-button></van-col>
           </van-row>
      </van-collapse-item>
    </van-collapse>
    <div class="glo-content">
        <div v-if="listLoading"><van-loading type="spinner" vertical>加载中</van-loading></div>
        <div v-else>
            <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="getAlarmList" :immediate-check="false">
                <div v-if="infoList.length == 0">
                    <van-empty description="数据列表为空" />
                </div>
                <div v-else class="inner-area">
                    <div v-for="(item,index) in infoList" :key="index" :class=" 'block ' +(item.status == '0'?'not-l':'yet-l')">
                        <div>
                          <div :class="'title '+(item.status == '0'?'color1':'')">
                              <div>{{item.type === 'batLevel' ? '电量告警' : '温度告警'}}</div>
                          </div>
                          <p :class="{'inner-txt':true,'color2':item.status == '0'}">告警内容：{{item.content || '暂无信息'}}</p>
                          <p :class="{'color2':item.status == '0'}">告警设备：{{item.devCode || '暂无信息'}}</p>
                          <p :class="{'color2':item.status == '0'}">告警时间：{{item.createTime || '2021-08-10 00:00:00'}}</p>
                          <p v-if="item.updateTime">结束时间：{{item.updateTime || '2021-08-10 00:00:00'}}</p>
                        </div>
                    </div>
                </div>
            </van-list>
        </div>
    </div>
    <!-- 筛选区域 -->
    <van-popup v-model="filter.area.showPicker" round position="bottom">
        <van-picker show-toolbar :columns="filter.area.columns" @cancel="filter.area.showPicker = false" @confirm="areaConfirm" />
    </van-popup>
    <!-- 筛选处理方式 -->
    <van-action-sheet v-model="filter.way.show" :actions="filter.way.actions" @select="waySelect" />
    <!-- 筛选时间段 -->
    <van-calendar ref="time" v-model="filter.time.show" type="range" :min-date="filter.time.minDate" :max-date="filter.time.maxDate" @confirm="timeConfirm" color="#48a9f9"/>
    <tabBar :parent="alarmtotal" />
  </div>
</template>
<script>
import tabBar from '@/components/icps/tabBar.vue'
var moment = require('moment');

export default {
  components: {
    tabBar
  },
  data() {
    return {
      activeName: '',
      infoList:[],
      filter:{
        area:{
          code:'',
          value:'',
          showPicker:false,
          columns:[]
        },
        way:{
          code:'',
          value:'',
          show:false,
          actions:[{name:'电量告警',id:'batLevel'},{name:'温度告警',id:'workTemp'}]
        },
        time:{
          start:'',
          end:'',
          minDate: new Date(2018, 0, 1),
          maxDate: new Date(),
          value:'',
          show:false
        }
      },
      bridgeCode:'',
      pageNum:1,
      pageSize:10,
      loading:false,
      finished:false,
      listLoading:false,
      alarmtotal:'',
      projectCode:null,
    }
  },
  
  methods:{
    // 筛选监测区域确认事件
    areaConfirm(v){
      this.filter.area.showPicker = false;
      this.filter.area.value = v.text;
      this.filter.area.code = v.code;
    },
    // 筛选处理方式确认事件
    waySelect(v){
      this.filter.way.show = false;
      this.filter.way.value = v.name;
      this.filter.way.code = v.id;
    },
    // 筛选时间确认事件
    timeConfirm(date) {
      const [start, end] = date;
      this.filter.time.show = false;
      this.filter.time.start = moment(start).format('YYYY-MM-DD');
      this.filter.time.end = moment(end).format('YYYY-MM-DD');
      this.filter.time.value = `${this.filter.time.start} 至 ${this.filter.time.end}`;
    },
    // 重置
    resetData(){
      this.filter.area.value = '';
      this.filter.way.value='';
      this.filter.time.value='';
      this.filter.area.code = '';
      this.filter.way.code = '';
      this.filter.time.start = '';
      this.filter.time.end = '';
      this.$refs.time.reset();
    },
    // 筛选
    filterData(){
      this.listLoading = true;
      this.finished = false;
      this.activeName = '';
      this.infoList = [];
      this.pageNum = 1;
      this.getAlarmList();
    },
    // 获取筛选区域列表
    areaList(){
      this.$api.ICPS.devList({projectCode:this.projectCode}).then( d =>{
        this.filter.area.columns = d?.map( v => ({text:v.devName,code:v.devCode}))
      })
    },
    // 加载告警信息列表
    getAlarmList(){
      let data = {
        beginTime:this.filter.time.start ? `${this.filter.time.start} 00:00:00` : void 0,
        endTime:this.filter.time.end ? `${this.filter.time.end} 23:59:59` :void 0,
        type:this.filter.way.code || void 0,
        devCode:this.filter.area.code || void 0,
        projectCode:this.projectCode
      }
      this.$api.ICPS.alarmList(this.pageNum,this.pageSize,data).then( d =>{
        if(Array.isArray(d)){
            this.listLoading = false;
            this.loading = false;
            this.infoList =this.infoList.concat(d)
            // 加载完毕
            if( d.length < this.pageSize){
              this.finished = true
              return
            }
            this.pageNum ++;
        }
      })
    }
  },
  mounted(){
    this.projectCode = window.sessionStorage.getItem('projectCode');
    this.getAlarmList();
    this.areaList();
  }
}
</script>
<style lang="scss" scoped>
.filter{text-align: left;}
.block{
    border: 1px solid #ececec;background-color: #d8f0f7;text-align: left;font-size: 0.8rem;padding: 2%;border-radius: 10px;color: #353535;margin-bottom: 2%;
    p{margin: 0;padding-bottom: 2%;color: #565656;}
    .inner-txt{text-indent: -5em;margin-left: 5em;padding-top: 2%;}
    .title{
      font-size: .95rem;font-weight: bold;text-align: center;padding: 2%;border-bottom: 1px solid #e2e2e2;color: #003561;
      .fa{position: absolute;top: 5%;right: 2%;}
      .not{color: #1989fa;}
      .yet{color: #aaaaaa;}
    }
    .footer{font-size:.75rem;display: flex;justify-content: space-between;border-top: 1px solid #e2e2e2;padding: 2% 0;}
    .emphasis{font-weight: bold;color: #003561;}
}
.text-center{
  text-align: center;
  button{
    padding: 0 20px;
    margin-top: 5%;
  }
}
.not-l{
  background-color: #019af3;
  color: #fff;
}
.yet-l{
  background-color: #d2d2d2;
}
.color1{
  color: #fff !important;
}
.color2{
  color: #efefef !important;
}
.inner-area{
  padding: 2%;
}
.glo-content{
  margin-bottom: 20%;
}
</style>